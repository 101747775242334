function base64encode(string: string, bufferType: BufferEncoding = 'base64'): string {
  if (!string) { return '' }
  if (import.meta.server) {
    return Buffer.from(string, 'utf-8').toString(bufferType)
  }
  else {
    return btoa(string)
  }
}

function base64decode(string: string, bufferType: BufferEncoding = 'base64'): string {
  if (!string) { return '' }
  if (import.meta.server) {
    return Buffer.from(string, bufferType).toString('utf-8')
  }
  else {
    return atob(string)
  }
}

export { base64encode, base64decode }
